import React, { useState } from "react";
import { Alert, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

import * as styles from "../styles/support.module.css";

export default function Support() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [alertText, setAlertText] = useState("");
  const [loading, setLoading] = useState(false);

  const submitMessage = async event => {
    setLoading(true);
    event.preventDefault();
    const body = {
      name,
      email,
      category,
      message,
    };

    try {
      let result = await fetch(
        "/.netlify/functions/contactowner/contactowner.js",
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      let feedback = await result.text();
      setAlertText(feedback);
    } catch (e) {
      console.log(e);
      throw e;
    } finally {
      setName("");
      setEmail("");
      setCategory("");
      setMessage("");
      setLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Leaderboard Golf App</title>
      </Helmet>
      <div className={styles.content}>
        <h1 style={{ textAlign: "center", margin: "40px" }}>Contact Us</h1>
        <p style={{ fontStyle: "italic", textAlign: "center", margin: "20px" }}>
          Reach out to us with any questions or feedback (and please provide an
          email address for us to contact you), and we will get back to you as
          soon as we can.
        </p>
        <div className={styles.form}>
          <form onSubmit={submitMessage}>
            <div className="row">
              <div className="col-12 col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control rounded"
                  placeholder="Your name"
                  style={{ fontWeight: 200 }}
                  required
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <input
                  type="email"
                  className="form-control rounded"
                  placeholder="Your email address"
                  style={{ fontWeight: 200 }}
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
            </div>
            <select
              className={styles.selectBox}
              style={{ fontWeight: 200, width: "100%", borderRadius: "3px" }}
              required
              value={category}
              onChange={e => setCategory(e.target.value)}
            >
              <option value="" disabled>
                Reason for Your Inquiry
              </option>
              <option value="Feedback">
                I have feedback to provide about the app.
              </option>
              <option value="Delete/Deactivate">
                I want to delete/deactivate my account.
              </option>
              <option value="Question">
                I have a question about how the app works, or your privacy
                policy.
              </option>
            </select>
            <textarea
              className="form-control rounded mb-5"
              placeholder="Your message"
              rows={7}
              style={{ fontWeight: 200 }}
              required
              value={message}
              onChange={e => setMessage(e.target.value)}
            ></textarea>
            <div className="d-flex justify-content-center">
              <button
                className="btn p-2 w-75 rounded-pill"
                style={{
                  backgroundColor: "#6b8bbb",
                  color: "#fff",
                  fontWeight: 100,
                }}
                type="submit"
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Send Message"
                )}
              </button>
            </div>
            {alertText && (
              <Alert
                variant="secondary"
                onClose={() => setAlertText("")}
                dismissible
                transition
                className="rounded-pill"
                style={{
                  fontWeight: 200,
                  width: "75%",
                  margin: "1em auto 0 auto",
                }}
              >
                {alertText}
              </Alert>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}
